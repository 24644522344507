<template>
  <div class="behold-content-wrapper">
    <section
      class="pt-8 lg:pt-16 pb-20 container-contents lg:pb-120 font-AvenirNext"
    >
      <div>
        <Logo class="flex justify-start" />
      </div>

      <div class="mt-20 lg:mt-24">
        <div class="flex justify-center">
          <svg
            class="w-92 h-23 lg:w-188 lg:h-49"
            viewBox="0 0 188 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.9012H13.9542C21.6944 1.9012 27.7994 4.78016 27.7994 13.3084C27.7994 17.4367 26.0006 20.9675 22.185 22.923C27.7994 23.9008 31.7785 26.997 31.7785 34.2216C31.7785 41.6091 26.3821 46.7152 17.0612 46.7152H0V1.9012ZM10.2476 19.4466H11.0652C15.4804 19.4466 17.8788 18.0886 17.8788 14.3405C17.8788 11.1356 15.5349 9.7776 11.0652 9.7776H10.2476V19.4466ZM10.2476 38.8388H13.4636C18.3149 38.8388 20.9858 37.3722 20.9858 33.8414C20.9858 29.713 18.3149 28.2464 13.4636 28.2464H10.2476V38.8388ZM64.5926 34.113H44.3155C44.3155 38.4042 47.913 39.8709 50.3659 39.8709C53.4729 39.8709 54.8356 39.2734 57.1795 36.7746L64.1566 40.2511C60.9951 45.5202 55.8713 47.53 49.6573 47.53C41.2085 47.53 34.2859 40.903 34.2859 32.8093C34.2859 24.6613 41.2085 18.0342 49.6573 18.0342C58.1606 18.0342 64.6471 23.0317 64.6471 32.8093C64.6471 33.1895 64.6471 33.787 64.5926 34.113ZM44.588 28.7896H55.2172C54.7811 25.8563 52.7643 24.281 49.9298 24.281C46.6048 24.281 44.7515 26.2366 44.588 28.7896ZM69.1714 46.7152V0H78.8194V21.7823H78.9284C81.1087 19.6095 83.8887 18.0342 86.9411 18.0342C92.0649 18.0342 99.2056 20.5873 99.2056 28.355V46.7152H89.5575V30.9624C89.5575 28.1921 87.6497 25.5304 84.5428 25.5304C81.6538 25.5304 78.8194 28.1921 78.8194 30.9624V46.7152H69.1714ZM141.123 46.7152V0H150.771V46.7152H141.123ZM155.295 32.8093C155.295 24.6613 161.018 18.0342 169.195 18.0342C172.956 18.0342 176.063 19.2836 178.352 21.5107V0H188V46.7152H178.461V43.5646H178.352C176.281 45.8461 173.01 47.53 169.195 47.53C161.018 47.53 155.295 40.9573 155.295 32.8093ZM165.542 32.8093C165.542 36.6117 168.159 39.3277 172.084 39.3277C175.845 39.3277 178.57 36.6117 178.57 32.8093C178.57 28.9526 175.681 26.2366 172.084 26.2366C168.431 26.2366 165.542 28.9526 165.542 32.8093Z"
              fill="#211A4C"
            />
            <path
              d="M121.243 49.0002C130.46 49.0002 137.932 41.5412 137.932 32.3402C137.932 23.1391 130.46 15.6802 121.243 15.6802C112.026 15.6802 104.553 23.1391 104.553 32.3402C104.553 41.5412 112.026 49.0002 121.243 49.0002Z"
              fill="#FFC60B"
            />
          </svg>
        </div>

        <div class="flex w-full justify-center mt-20 lg:mt-120">
          <figure class="w-full mx-auto">
            <img
              src="../assets/images/behold/behold-banner.png"
              alt="Screenshot of Behold App"
            />
          </figure>
        </div>

        <div class="w-full lg:w-1/2 mt-12 lg:mt-8">
          <p class="banner-section-text xl:pr-4">
            Prayer is an important part of Christian living, but many people
            struggle to do it daily. It's hard to find the time and figure out a
            good structure. We designed Behold to make it easy for people to fit
            prayer into their daily lives through daily guided prayers that
            invite them to hear God’s word and meditate on its meaning.
          </p>
        </div>
      </div>
    </section>

    <section class="py-20 lg:py-120 bg-gray-darker what-unique">
      <div class="container-contents">
        <h3 class="question-section-title text-gray-dark">
          What’s unique about Behold?
        </h3>
        <!--- First Image -->
        <article>
          <div
            class="container mx-auto flex lg:flex-row flex-col items-center mt-20 lg:mt-120"
          >
            <div class="lg:w-1/2 text-left">
              <h5 class="article-title text-blue">Daily Guided Meditations</h5>

              <p class="article-paragraph xl:pr-2">
                Daily meditations and scheduled reminders provide a way for
                Christians who need help making time to read the scripture,
                meditate and pray, to do so in 10 minutes or less. The guided
                meditations encourage listeners to quiet themselves, listen to
                the word, reflect in silence, and respond in vocal prayer.
              </p>
            </div>

            <div class="w-full lg:w-3/6 lg:ml-7 mt-16 lg:mt-0">
              <img
                class="object-cover object-center rounded"
                src="../assets/images/behold/behold-3.png"
                alt="Screenshot of Behold App"
              />
            </div>
          </div>
        </article>

        <!--- Second Image -->
        <article class="mt-75 lg:mt-163">
          <div class="container mx-auto flex lg:flex-row flex-col items-center">
            <div class="w-full lg:w-3/6 order-2 lg:order-1 mt-16 lg:mt-0">
              <img
                class="object-cover object-center rounded"
                src="../assets/images/behold/behold-4.png"
                alt="Screenshot of Behold App"
              />
            </div>
            <div class="lg:w-1/2 text-left order-1 lg:order-2 lg:ml-7">
              <h5 class="article-title">Tropical Collections of Prayers</h5>
              <p class="article-paragraph">
                Collections provide grouped meditations on topics people can
                relate to. For people who need help with certain aspects of
                their lives or want to reflect on particular issues, collections
                are a great way to have focused meditation sessions.
              </p>
            </div>
          </div>
        </article>

        <!--- Third Image -->
        <article class="mt-75 lg:mt-163">
          <div class="container mx-auto flex lg:flex-row flex-col items-center">
            <div class="lg:w-1/2 text-left">
              <h5 class="article-title">
                Save Reflections & Favourite Meditations
              </h5>

              <p class="article-paragraph xl:pr-2">
                At the end of every meditation, users are invited to reflect,
                write down what comes to mind and save their reflections. They
                can also save their favorite meditations. This allows users to
                go back to meditations or reflections they connect to deeply.
              </p>
            </div>

            <div class="w-full lg:w-3/6 lg:ml-7 mt-16 lg:mt-0">
              <img
                class="object-cover object-center rounded"
                src="../assets/images/behold/behold-5.png"
                alt="Screenshot of Behold App"
              />
            </div>
          </div>
        </article>

        <!--- Fourth Image -->
        <article class="mt-75 lg:mt-163">
          <div
            class="container mx-auto flex lg:flex-row flex-col justify-between items-center"
          >
            <div class="w-full lg:w-2/5 order-2 lg:order-1 mt-16 lg:mt-0">
              <img
                class="object-cover object-center rounded"
                src="../assets/images/behold/behold-6.png"
                alt="Screenshot of Behold App"
              />
            </div>
            <div class="lg:w-1/2 text-left order-1 lg:order-2 lg:ml-7">
              <h5 class="article-title">Admin Content Management System</h5>
              <p class="article-paragraph">
                Behold’s content is created and managed by a custom content
                management system (CMS). Allowing admins to seamlessly create
                content and to preview content before publishing, were some of
                the key requirements of the Admin CMS.
              </p>
            </div>
          </div>
        </article>
      </div>
    </section>

    <section class="py-20 lg:py-120 how-we-got-here bg-white">
      <article class="container-contents">
        <h3 class="question-section-title text-gray-lighter">
          So how did we get here?
        </h3>

        <div class="bg-white mt-20 lg:mt-120">
          <div class="container mx-auto lg:w-2/3">
            <h5 class="article-title">Team & Role</h5>
            <p class="article-paragraph text-black-dark font-normal">
              Our team was made up of 2 Product Owners/Product Managers, a
              Software Developer and a UX Designer (myself). I led the discovery
              stage of this project, I also handled branding, UX design and UI
              design.
            </p>
          </div>
        </div>

        <div class="bg-white mt-20 lg:mt-120">
          <div class="container mx-auto lg:w-2/3">
            <h5 class="article-title">From Discovery to Proposed Solution</h5>
            <p class="article-paragraph mt-6 lg:mt-8">
              Goals and challenges which needed to be addressed were:
            </p>

            <ul class="list-disc ml-4 mt-4 lg:mt-6">
              <li class="my-4 article-paragraph">
                To offer guided meditations rooted in scripture
              </li>
              <li class="my-4 article-paragraph">
                To offer a series of popular topics by diverse guides
              </li>
              <li class="my-4 article-paragraph">
                To offer simple tracking to help users build their practice
              </li>
              <li class="my-4 article-paragraph">
                To offer opportunities to connect with community and go deeper
              </li>
            </ul>
            <p class="mt-4 lg:my-6 article-paragraph">
              I ran a design sprint to dive deeper into the challenges and
              explore possible ways to achieve the goals. By the end of the
              sprint, we had a wide range of possibilities to explore. A few of
              these possibilities formed what is now the first version of
              Behold.
            </p>
          </div>
        </div>
      </article>
    </section>

    <vm-back-top :bottom="100" :duration="1000" :timing="'ease'">
      <div class="back-to-top-arrow">
        <svg
          class="h-12 w-12 lg:w-79 lg:h-79"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.0001 46.1413C36.2282 46.1413 46.141 36.2284 46.141 24.0003C46.141 11.7722 36.2282 1.85938 24.0001 1.85938C11.772 1.85938 1.85913 11.7722 1.85913 24.0003C1.85913 36.2284 11.772 46.1413 24.0001 46.1413Z"
            fill="#211A4C"
            fill-opacity="0.24"
          />
          <path
            d="M34.6151 29.1682C33.89 29.8895 32.7189 29.8895 31.9938 29.1682L24.0186 21.193L16.0434 29.1682C15.2644 29.8356 14.0896 29.7445 13.4221 28.9656C12.8254 28.2704 12.8254 27.2423 13.4221 26.547L22.7173 17.2519C23.4423 16.5306 24.6135 16.5306 25.3385 17.2519L34.6336 26.547C35.3494 27.2776 35.3419 28.4488 34.6151 29.1682Z"
            fill="white"
          />
        </svg>
      </div>
    </vm-back-top>

    <article class="py-20 lg:py-120 how-we-got-here bg-white">
      <div class="container-contents">
        <div class="lg:flex justify-between">
          <div class="w-full lg:w-2/5">
            <h5 class="article-title">Branding Behold</h5>
            <p class="article-paragraph mt-6 lg:mt-8">
              To appropriately brand behold, I created some brand questions and
              shared them with the relevant team members. Their answers formed
              the basis of Behold’s brand.
            </p>
          </div>

          <div class="w-full lg:w-1/2">
            <div class="mt-16 lg:mt-0">
              <img
                src="../assets/images/behold/logo-brand.png"
                alt="behold branding colors"
              />
            </div>
          </div>
        </div>
        <div class="w-full lg:w-2/3 mx-auto mt-5 lg:mt-17">
          <div class="flex justify-between">
            <div class="w-1/2 lg:w-1/3">
              <img src="../assets/images/behold/logo-1.png" alt="Behold logo" />
            </div>

            <div class="ml-11 lg:ml-0"></div>

            <div class="w-1/2 lg:w-1/3">
              <img src="../assets/images/behold/logo-2.png" alt="Behold logo" />
            </div>
          </div>
          <div class="mt-16 lg:mt-120 w-1/3 mx-auto">
            <img src="../assets/images/behold/logo-3.png" alt="Behold logo" />
          </div>
        </div>
      </div>
    </article>

    <article
      class="py-20 lg:py-120 text-blue bg-gray-darker from-product-requirement"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">
            From Product Requirements to Wireframing & Prototyping
          </h5>
          <p class="article-paragraph">
            A detailed product requirement document (PRD) was created to guide
            the design and development of Behold. I created wireframes and
            prototypes based on the PRD which were shared with the team. We
            spent some time iterating on how users would access and interact
            with Behold’s features and functionalities, all the while,
            increasing the fidelity as we approached usability testing.
          </p>
        </div>
      </div>

      <div class="mt-20 lg:mt-120 px-58 lg:px-0 lg:w-9/12 mx-auto">
        <div
          class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-16 lg:gap-x-32"
        >
          <img
            src="../assets/images/behold/behold-7.png"
            alt="wifeframe of behold screen"
          />
          <img
            src="../assets/images/behold/behold-8.png"
            alt="wifeframe of behold screen"
          />
          <img
            src="../assets/images/behold/behold-9.png"
            alt="wifeframe of behold screen"
          />
        </div>
      </div>
    </article>

    <article class="py-20 lg:py-120 text-black-dark bg-white usability-testing">
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Usability Testing</h5>
          <p class="article-paragraph">
            With an acceptable wireframe and prototype, we created an interview
            guide, recruited 5 participants and conducted usability studies. The
            findings from the studies were summarised in a slide deck and
            presented to the team. The summary deck also included
            recommendations on how to improve the product based on users'
            feedback.
          </p>
        </div>
      </div>
    </article>

    <article
      class="py-20 lg:py-120 text-blue bg-gray-darker from-product-requirement"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">Visual Design, Iteration & Development</h5>
          <p class="article-paragraph">
            The visual design and prototype were generated based on the
            wireframes, recommendations from the usability tests and Behold’s
            brand guidelines. We explored different visual treatments that were
            centered around simplicity and serenity but still gave off a bold
            and colourful personality. Interactions and accessibility were also
            iterated on to provide an experience that is seamless and
            frictionless. We kept iterating on the visual design up until and
            even through development. Development was done in milestones, with
            each milestone going through quality assurance before being deployed
            to Production.
          </p>
        </div>
      </div>

      <div class="mt-20 lg:mt-120 px-58 lg:px-0 lg:w-9/12 mx-auto">
        <div
          class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-16 lg:gap-x-32 lg:gap-y-20"
        >
          <img
            src="../assets/images/behold/behold-10.png"
            alt="wifeframe of behold screen"
          />
          <img
            src="../assets/images/behold/behold-11.png"
            alt="wifeframe of behold screen"
          />
          <img
            src="../assets/images/behold/behold-12.png"
            alt="wifeframe of behold screen"
          />
          <img
            src="../assets/images/behold/behold-13.png"
            alt="wifeframe of behold screen"
          />
          <img
            src="../assets/images/behold/behold-14.png"
            alt="wifeframe of behold screen"
          />
          <img
            src="../assets/images/behold/behold-15.png"
            alt="wifeframe of behold screen"
          />
        </div>
      </div>
    </article>

    <article
      class="py-20 lg:py-120 text-black-dark bg-white usability-testing from-beta"
    >
      <div class="container-contents">
        <div class="w-full lg:w-2/3 mx-auto">
          <h5 class="article-title">From Beta to Launch</h5>
          <p class="article-paragraph">
            We released a beta version of Behold to a handful of beta testers.
            They gave us continuous feedback on issues they’d experienced which
            we fixed as they came up. A stable version of Behold was released a
            few months later.
          </p>
        </div>

        <div class="w-full lg:w-2/3 mx-auto pt-16 lg:mt-24">
          <h5 class="article-title">Recap & Takeaways</h5>
          <h6 class="article-title title-2 text-lg lg:text-2xl mt-6 lg:mt-8">
            Getting Testers Involved Early Paid Off in Ways We Didn’t Expect
          </h6>
          <p class="article-paragraph">
            Getting testers involved with Behold’s development was a great
            decision and worked really well for us. All the way from usability
            testing, to beta testing , to launch, testers gave valuable insights
            on what direction we should take and what issues they experienced
            while testing. Because we kept them in loop all through, testers
            translated into our first users when Behold launched.
          </p>
        </div>

        <div class="w-full lg:w-2/3 mx-auto mt-16 lg:mt-20">
          <h6 class="article-title title-2 text-lg lg:text-2xl">
            Releasing Features in Bite-Sized Chunks Translated to Easy
            Digestibility
          </h6>
          <p class="article-paragraph">
            Daily meditations was the first thing to be released. Collections
            and ability to save reflection and meditations followed soon after.
            Because users were quite familiar with how Behold’s meditations
            work, collections and, saving reflection and meditations didnt come
            as much of a shock to them. Many of the issues with meditations had
            already been resolved based on their feedback from the initial
            release.
          </p>
        </div>
      </div>
    </article>

    <section class="py-20 lg:py-120 how-we-got-here bg-white">
      <article
        class="container-contents flex flex-wrap lg:flex-nowrap justify-center lg:justify-start items-center"
      >
        <figure class="w-full lg:w-8/12">
          <img
            src="../assets/images/behold/behold-16.png"
            alt="Download mobile app"
          />
        </figure>

        <div class="lg:w-30"></div>

        <div class="flex flex-col w-2/3 lg:w-1/5 mt-2.5 lg:mt-0">
          <a
            class="inline-block"
            href="https://apps.apple.com/ug/app/behold-christian-prayer/id1528200137"
            target="_blank"
          >
            <img
              src="../assets/images/behold/app-store-badge.png"
              alt="Download on Apple store"
            />
          </a>
          <a
            class="mt-16"
            href="https://play.google.com/store/apps/details?id=com.enle.behold&hl=en&gl=US"
            target="_blank"
          >
            <img
              src="../assets/images/behold/google-play-badge.png"
              alt="Download on Google play store"
            />
          </a>
        </div>
      </article>
    </section>

    <div class="container-contents flex flex-col lg:flex-row justify-between">
      <div class="flex justify-center flex-col order-2 lg:order-1 mb-6 lg:mb-0">
        <p class="text-gray-lighter font-semibold text-center lg:text-left">
          PREVIOUS
        </p>
        <div class="flex justify-center items-center mt-6 lg:mt-8">
          <div class="w-full text-center lg:text-left relative">
            <a
              href="/plexus"
              class="behold-link-border outline-none focus:outline-none article-link text-blue text-lg"
            >
              Plexus Case Study
            </a>
          </div>
        </div>
      </div>

      <div class="mb-20 lg:mb-0 order-1 lg:order-2">
        <p class="text-gray-lighter text-center lg:text-right font-semibold">
          NEXT
        </p>
        <div class="flex justify-center items-center mt-6 lg:mt-8">
          <div class="w-full text-center lg:text-left relative">
            <a
              href="/oasis"
              class="behold-link-border outline-none focus:outline-none article-link text-blue text-lg"
            >
              Oasis Smart Thermostat Case Study
            </a>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Logo from "@/components/Logo";
export default {
  name: "Behold",
  components: { Logo, Footer },
};
</script>

<style lang="scss" scoped>
.behold-link-border {
  position: relative;
  text-decoration: none;

  &::before {
    background: #211a4c;
    bottom: -4px;
    left: 0;
    content: "";
    display: block;
    height: 3px;
    width: 100%;
    position: absolute;
  }
  &:hover {
    &::before {
      animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }
  }
  &:hover:before {
    animation: line 0.95s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}
@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
